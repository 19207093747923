<template>
    <v-container fluid style=" margin-bottom:100px">
        <!-- Filter -->
        <v-card>
            <v-card-title>Filter</v-card-title>
            <v-container fluid >
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-autocomplete
                            v-model="mill"
                            :items="mills"
                            default=""
                            item-value="mill_id"
                            item-text="mill_name"
                            label="MILL"
                            clearable
                            @change="(event) => getDivision(event)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-autocomplete
                            v-model="division"
                            :items="divisions"
                            default=""
                            item-value="class"
                            item-text="descr"
                            label="Division"
                            clearable
                            @change="(event) => getMachineType(event)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-autocomplete
                            v-model="machine_type"
                            :items="machine_types"
                            item-value="mach_type"
                            :item-text="item => item.mach_type +' - '+ item.descr"
                            label="Machine Type"
                            clearable
                            @change="(event) => getMachineName(event)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-autocomplete
                            v-model="machine"
                            :items="machines"
                            item-value="mach_id"
                            :item-text="item => item.descr"
                            label="Machine Name"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-autocomplete
                            v-model="category_maintenance"
                            :items="category_maintenances"
                            item-value="category_id"
                            :item-text="item => item.description"
                            label="Maintenance Category"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-autocomplete
                            v-model="req_sparepart"
                            :items="req_spareparts"
                            item-value="id"
                            :item-text="item => item.label"
                            label="Require Sparepart"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="2">
                        <v-dialog
                            ref="req_dialog_from"
                            v-model="modal_req_date_from"
                            :return-value.sync="req_date_from"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="req_date_from"
                                label="Req Date from"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="req_date_from"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal_req_date_from = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.req_dialog_from.save(req_date_from)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="2">
                        <v-dialog
                            ref="req_dialog_to"
                            v-model="modal_req_date_to"
                            :return-value.sync="req_date_to"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="req_date_to"
                                label="Req Date to"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="req_date_to"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal_req_date_to = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.req_dialog_to.save(req_date_to)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="2">
                        <v-dialog
                            ref="start_dialog_from"
                            v-model="modal_start_date_from"
                            :return-value.sync="start_date_from"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="start_date_from"
                                label="Start Date from"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="start_date_from"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal_start_date_from = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.start_dialog_from.save(start_date_from)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="2">
                        <v-dialog
                            ref="start_dialog_to"
                            v-model="modal_start_date_to"
                            :return-value.sync="start_date_to"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="start_date_to"
                                label="Start Date to"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="start_date_to"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal_start_date_to = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.start_dialog_to.save(start_date_to)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="2">
                        <v-dialog
                            ref="finish_dialog_from"
                            v-model="modal_finish_date_from"
                            :return-value.sync="finish_date_from"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="finish_date_from"
                                label="Finish Date from"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="finish_date_from"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal_finish_date_from = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.finish_dialog_from.save(finish_date_from)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="2">
                        <v-dialog
                            ref="finish_dialog_to"
                            v-model="modal_finish_date_to"
                            :return-value.sync="finish_date_to"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="finish_date_to"
                                label="Finish Date to"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="finish_date_to"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal_finish_date_to = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.finish_dialog_to.save(finish_date_to)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" class="text-right">
                        <v-btn
                            class="mr-2"
                            color="info"
                            elevation="2"
                            large
                            rounded
                            :disabled="disabledExport">
                            <download-excel
                                class="btn"
                                :fetch           = "exportExcel"
                                :fields="unschedule_column"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                Download Excel
                            </download-excel>    
                        </v-btn>
                        <v-btn
                            class="mr-2"
                            color="error"
                            elevation="2"
                            large
                            rounded
                            @click="search()"
                        >
                            Search
                        </v-btn>
                    </v-col>
                    
                </v-row>
            </v-container>
        </v-card>
        <!-- DATA TABLE -->
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Maintenance
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="no_wr"
                                        append-icon="mdi-magnify"
                                        label="Search No WR"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="unschedules"
                                    :options.sync="option_items"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItem"
                                    :page.sync="pagination"
                                    page-count="10"
                                    item-key="key"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.start_date`]="{ item }">
                                        <div v-if="item.start_date != '1900-01-01 00:00:00.000'">
                                            {{item.start_date}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.finish_date`]="{ item }">
                                        <div v-if="item.finish_date != '1900-01-01 00:00:00.000'">
                                            {{item.finish_date}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                small
                                                class="mr-2"
                                                @click="editItem(item)"
                                                v-on="on"
                                                >
                                                mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-dialog v-model="dialog" max-width="1200px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>Detail Maintenance</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>
                                            Detail Maintenance
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_details"
                                            :items="unschedule_details"
                                            :options.sync="option_details"
                                            :loading="loading_detail"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItemDetail"
                                            :page.sync="paginationDetail"
                                            page-count="10"
                                            item-key="key"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip v-if="item.require_sparepart === '1'" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                        small
                                                        class="mr-2"
                                                        @click="showSparePart(item)"
                                                        v-on="on"
                                                        >
                                                        mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span>Show Req Sparepart</span>
                                                </v-tooltip>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogSparePart" max-width="800px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>Request Sparepart</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-5">
                                    <v-col cols="12">
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="request_sparepart"
                                                append-icon="mdi-magnify"
                                                label="Search No Article"
                                                single-line
                                                @keyup.enter="search_sparepart"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_spareparts"
                                            :items="spareparts"
                                            :options.sync="option_spareparts"
                                            :loading="loading_sparepart"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItemSparepart"
                                            :page.sync="paginationSparePart"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.req_qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.req_qty) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeSparePart">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import {format} from 'date-fns';

  export default {
    data() {
        return {
            tab: '',
            loading: false,
            mills: [],
            mill: '',
            divisions:[],
            division:'',
            machine_types: [],
            machine_type: '',
            machines: [],
            category_maintenance:'',
            category_maintenances:[],
            req_sparepart:'',
            req_spareparts: [
                {
                    'id' : 1,
                    'label' : 'Y'
                },
                {
                    'id' : 0,
                    'label' : 'N'
                }
            ],
            no_wr:'',
            machine: '',
            status_wo: '4',
            description: '',
            modal_req_date_from:false,
            req_date_from: '',
            modal_req_date_to:false,
            req_date_to: '',
            modal_start_date_from:false,
            start_date_from: '',
            modal_start_date_to:false,
            start_date_to: '',
            modal_finish_date_from:false,
            finish_date_from: '',
            modal_finish_date_to: false,
            finish_date_to: '',
            headers: [
                { text: "Actions", value: "actions", sortable: false },
                {
                    text: "No WR",
                    align: "start",
                    value: "no_wr",
                },
                { text: "Mill Id", value: "mill_id" },
                { text: "Request Date", value: "request_date" },
                { text: "Masalah", value: "observation" },
                { text: "Koreksi Masalah", value: "correction" },
                { text: "Start Date", value: "start_date" },
                { text: "Finish Date", value: "finish_date" },
                { text: "Lost Time", value: "total_lost_time" },
                { text: "PIC", value: "action_by" },
                { text: "Down Time", value: "total_down_time" },
                { text: "Duration", value: "total_duration" },
                { text: "Sparepart", value: "descr" },
                { text: "Qty", value: "req_qty" },
            ],
            unschedules: [],
            unschedule_column:{
                'No WR': 'no_wr',
                'Mill Id': 'mill_id',
                'Request Date': 'request_date',
                'Masalah': 'observation',
                'Koreksi Masalah': 'correction',
                'Start Date': 'start_date',
                'Finish Date': 'finish_date',
                'Lost Time': 'total_lost_time',
                'PIC': 'action_by',
                'Down Time': 'total_down_time',
                'Duration': 'total_duration',
                'Sparepart': 'descr',
                'Qty': 'req_qty',
            },
            disabledExport:false,
            unschedule_query_data:[],
            fileType:'xlsx',
            fileName:'siji',
            option_items: {},
            totalItem: 10,
            pagination: 1,

            header_details:[
                {
                    text: "No",
                    align: "start",
                    value: "wr_action_no",
                },
                {
                    text: "No WR",
                    value: "no_wr",
                },
                { text: "Mill Name", value: "mil_name" },
                { text: "Machine Type", value: "mach_type" },
                { text: "Nama Mesin", value: "name_machine" },
                { text: "Action Type", value: "action_type" },
                { text: "Status Action", value: "status_action" },
                { text: "Shift", value: "shift" },
                { text: "Req Sparepart", value: "require_sparepart" },
                { text: "Observation", value: "observation" },
                { text: "Correction", value: "correction" },
                { text: "Remark Action", value: "remark_action" },
                { text: "Remark Inspection", value: "remark_inspection" },
                { text: "Start Date", value: "start_date" },
                { text: "Finish Date", value: "finish_date" },
                { text: "Duration", value: "duration" },
                { text: "Lost Time", value: "loss_time" },
                { text: "Down Time", value: "downtime" },
                { text: "Action By", value: "action_by" },
                { text: "Inspected by", value: "inspected_by" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            unschedule_details: [],
            option_details: {},
            loading_detail: false,
            totalItemDetail: 10,
            paginationDetail: 1,
            dialog: false,
            text_dialog: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            mill_id: '',
            mill_ids: [],
            mach_id: '',
            mach_ids: [],
            mach_type: '',
            mach_types: [],
            requested_by: '',
            employees: [],
            request_date: '',
            dateProps: {
                headerColor: 'blue'
            },
            status: '',
            statuses: [
                {
                    'id' : 1,
                    'label' : 'Draft'
                },
                {
                    'id' : 2,
                    'label' : 'Maintenance'
                }
            ],
            description: '',
            btnSaveNew: '',
            dialog_save: false,
            detailMaintenance: {},
            display: 'none',
            dialogSparePart: false,
            request_sparepart: '',
            header_spareparts: [
                {
                    text: "Article Id",
                    align: "start",
                    value: "article_id",
                },
                { text: "Nama Produk", value: "name_product" },
                { text: "Req Qty", value: "req_qty" },
                { text: "Note", value: "note" }
            ],
            spareparts: [],
            option_spareparts: {},
            loading_sparepart: false,
            totalItemSparepart: 10,
            paginationSparePart: 1,
            detailSparePart: {},
            json_fields: {
                "Complete name": "name",
                City: "city",
                Telephone: "phone.mobile",
                "Telephone 2": {
                    field: "phone.landline",
                    callback: (value) => {
                    return `Landline Phone - ${value}`;
                    },
                },
            },
            json_data: [
            {
                name: "Tony Peña",
                city: "New York",
                country: "United States",
                birthdate: "1978-03-15",
                phone: {
                mobile: "1-541-754-3010",
                landline: "(541) 754-3010",
                },
            },
            {
                name: "Thessaloniki",
                city: "Athens",
                country: "Greece",
                birthdate: "1987-11-23",
                phone: {
                mobile: "+1 855 275 5071",
                landline: "(2741) 2621-244",
                },
            },
            ],
            json_meta: [
            [
                {
                key: "charset",
                value: "utf-8",
                },
            ],
            ],
        }
    },
    mounted(){
        this.getMill()
        this.getCategoryMaintenance()
        // this.getEmployee()
    },
    methods:{
        closeSparePart(){
            this.dialogSparePart = false
        },
        search_sparepart(){

        },

        close(){
            this.dialog = false
            this.text_dialog = ''
            this.clear()
            // this.search(1, 10)
        },
        getEmployee(){
            var data = {
                'nik' : '',
                'category' : '',
                'nama': '',
                'is_active' : 1,
                'page' : 1,
                'item_per_page' : 100
            }

            this.$store
            .dispatch("getMasterMaintenanceEmployee", data)
            .then(res => {
                this.employees = res.data.data
            })
            .catch((err) => {

            })
        },
        async getMill(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.mills = res.data.data
                this.mill_ids = res.data.data
			})
			.catch(err => {
			})
        },
        async getDivision(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/master_division?mill_id=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                console.log(res);
                console.log(res.data.errorCode);
                if(res.data.errorCode == "00"){
                    this.divisions = res.data.data.data
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: res.data.errorMessage,
                        visible: true
                    };
                }
                
			})
			.catch(err => {
			})
        },
        async getMachineType(value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_type?mill_id=${this.mill ? this.mill : ''}&class=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machine_types = res.data
                this.mach_types = res.data
			})
			.catch(err => {
			})
        },
        async getMachineName(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_id?mill_id=${this.mill ? this.mill : ''}&mach_type=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machines = res.data
                this.mach_ids = res.data
			})
			.catch(err => {
			})
        },
        async getCategoryMaintenance(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/maintenance_category`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.category_maintenances = res.data
			})
			.catch(err => {
			})
            
        },

        async search(page = 1, itemsPerPage = 10){
            this.loading = true
            var url = `${process.env.VUE_APP_URL}/api/kka/maintenance/query_unschedule_maintenance?mill_id=${this.mill ? this.mill : ''}&class=${this.division ? this.division : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&no_wr=${this.no_wr ? this.no_wr : ''}&status=${this.status_wo ? this.status_wo : ''}&description=${this.description ? this.description : ''}&category_maintenance=${this.category_maintenance ? this.category_maintenance : ''}&req_sparepart=${this.req_sparepart ? this.req_sparepart : ''}&request_date_from=${this.req_date_from ? this.req_date_from : ''}&request_date_to=${this.req_date_to ? this.req_date_to : ''}&start_date_from=${this.start_date_from ? this.start_date_from : ''}&start_date_to=${this.start_date_to ? this.start_date_to : ''}&finish_date_from=${this.finish_date_from ? this.finish_date_from : ''}&finish_date_to${this.finish_date_to ? this.finish_date_to : ''}&pagination=${itemsPerPage}&page=${page > 1 ? page : ''}`

            await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.unschedules = res.data.data
                this.totalItem = res.data.total
                this.pagination = res.data.current_page
            })
        },

        async exportExcel(){
            var url = `${process.env.VUE_APP_URL}/api/kka/maintenance/query_unschedule_maintenance?mill_id=${this.mill ? this.mill : ''}&class=${this.division ? this.division : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&no_wr=${this.no_wr ? this.no_wr : ''}&status=${this.status_wo ? this.status_wo : ''}&description=${this.description ? this.description : ''}&category_maintenance=${this.category_maintenance ? this.category_maintenance : ''}&req_sparepart=${this.req_sparepart ? this.req_sparepart : ''}&request_date_from=${this.req_date_from ? this.req_date_from : ''}&request_date_to=${this.req_date_to ? this.req_date_to : ''}&start_date_from=${this.start_date_from ? this.start_date_from : ''}&start_date_to=${this.start_date_to ? this.start_date_to : ''}&finish_date_from=${this.finish_date_from ? this.finish_date_from : ''}&finish_date_to${this.finish_date_to ? this.finish_date_to : ''}}`
            const response = await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            return response.data.data;
        },

        startDownload(){
            this.loading = true
            this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },

        async detail(page = 1, itemsPerPage = 10){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance?mill_id=${this.detailMaintenance.mill_id}&mach_type=${this.detailMaintenance.mach_type}&mach_id=${this.detailMaintenance.mach_id}&no_wr=${this.detailMaintenance.no_wr}&status_action=3&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_detail = false
                this.unschedule_details = res.data.data
                this.totalItemDetail = res.data.total
                this.paginationDetail = res.data.current_page
            })
        },
        editItem(item){
            this.display = 'none'
            this.detailMaintenance = item
            // this.$store.dispatch('getDetailMaintainanceAction', { item })
            this.dialog = true
            this.text_dialog = 'Edit'
            this.clear()
            this.unschedule_details = []
            this.loading_detail = true
            this.detail(1, 10)
        },
        clear(){
            this.mill_id = ''
            this.mach_type = ''
            this.mach_id = ''
            this.requested_by = ''
            this.request_date = ''
            this.status = ''
            this.description = ''
        },
        async save(){
            this.dialog_save = true

            if (this.mill_id === '' || this.mach_type === '' || this.mach_id === '' || this.requested_by === '' || this.request_date === '' || this.status === '' || this.description === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill data",
                    visible: true
                };

                this.dialog_save = false

                return false;
            }

            if (this.btnSaveNew === 'New') {
                await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance`, {
                    'mill_id': this.mill_id,
                    'mach_type': this.mach_type,
                    'mach_id': this.mach_id,
                    'description': this.description,
                    'requested_by': this.requested_by,
                    'request_date': this.request_date,
                    'status': this.status
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    // this.loading = false
                    this.dialog_save = false
                    this.dialog = false

                    this.search(1,10)
                    this.clear()
                }).catch(err => {
                    // this.loading = false
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            } else {
                
            }
        },
        showSparePart(item){
            this.detailSparePart = item
            this.dialogSparePart = true
            this.spareparts = []
            this.loading_sparepart = true
            this.getSparePart(1, 10)
        },
        async getSparePart(page = 1, itemsPerPage = 10){

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart?mill_id=${this.detailSparePart.mill_id}&no_wr=${this.detailSparePart.no_wr}&wr_action_no=${this.detailSparePart.wr_action_no}&page=${page > 1 ? page : ""}&pagination=${itemsPerPage}`,
            {
                headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`},
            }
            )
            .then((res) => {
                this.loading_sparepart = false

                this.spareparts = res.data.data
                this.totalItemSparepart = res.data.total
                this.paginationSparePart = res.data.current_page

            });
        },
    },
    watch: {
        option_items: {
            handler () {
                const { page, itemsPerPage } = this.option_items
                this.search(page, itemsPerPage)
            },
            deep: true,
        },
        option_details:{
            handler () {
                const { page, itemsPerPage } = this.option_details
                if (this.detailMaintenance) {
                    this.detail(page, itemsPerPage)
                }
            },
            deep: true,
        },
        option_spareparts:{
            handler () {
                const { page, itemsPerPage } = this.option_spareparts
                if (this.detailSparePart) {
                    this.getSparePart(page, itemsPerPage)
                }
            },
            deep: true,
        }
        // detailMaintenance
    }
  }
</script>
